import axios from 'axios'
import { useEffect } from 'react'
import {
    BrowserRouter,
    Route,
    Routes,
    useParams,
    useSearchParams,
} from 'react-router-dom'

/*
    URL MUST be added as a url parameter titled 'url', and encoded using the encodeURLComponent function.
*/

const Redirect: React.FunctionComponent = () => {
    //Gets query parameters from the URL.
    const [searchParams, setSearchParams] = useSearchParams()
    const paramsStringified = Object.fromEntries([...searchParams])
    const url = paramsStringified.url
    useEffect(() => {
        if (url !== undefined) {
            window.location.replace(url)
        }
    }, [url])
    return <div></div>
}

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<Redirect />} />
            </Routes>
        </BrowserRouter>
    )
}

export default App
